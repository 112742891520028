<template>
    <Transition name="slide" appear>
    <div class="details-container" @scroll="onScroll">
        <div class="details-inner">
            <swiper
                :modules="modules"
                :space-between="20"
                :loop="true"
                :pagination="{clickable: true}"
                :autoplay="{
                    delay: 7000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true
                }"
            >
                <swiper-slide v-for="text in swiperText" :key="text.img">
                    <div class="back-menu" v-if="showBackIcon">
                        <div class="d-flex">
                            <mdicon name="chevron-left" @click="router.go(-1)" size="30"/>
                        </div>
                    </div>
                    <img :src="text.img" alt="image" class="w-100" height="200"/>
                </swiper-slide>
            </swiper>
            <!-- <img src="@/assets/lamp.jpg" class="w-100" height="200"/> -->

            <div class="container">
                <div class="d-flex flex-row justify-content-between align-items-center" v-if="serviceDetails">
                    <div class="d-flex flex-row justify-content-start align-items-center mt-2">
                        <div>
                            <img :src="baseUrl + serviceDetails.logo" width="40" style="border-radius: 100px;" v-if="serviceDetails.logo"/>
                            <img src="@/assets/wallpaper.png" width="40" style="border-radius: 50%;" v-else/>
                        </div>
                        <div class="ms-3 lh-1">
                            <span class="fw-bold text-success">{{ serviceDetails.store_name }}</span><br>
                            <span style="font-size: 15px; color: lightslategray">{{ serviceDetails.address.address }} {{ serviceDetails.address.barangay }}</span>
                        </div>
                    </div>
                    <div>
                        <mdicon name="chevron-down" size="30"/>
                    </div>
                </div>

                <div class="mt-4 lh-1">
                    <span class="fw-bold fs-3">From its medieval origins to the digital era, learn everything there is to know about the ubiquitous lorem ipsum passage.</span>
                </div>

                <div class="mt-3">
                    <p>{{ displayedText }}</p>
                    <p v-if="!isCollapsed">{{ secondSentence }}</p>
                    <p class="text-btn text-success fw-bold text-end" @click="expandText" v-if="isCollapsed" style="font-size: 13px;">Show more</p>
                    <p class="text-btn text-success fw-bold text-end" @click="collapseText(50)" v-else style="font-size: 13px;">Show less</p>
                </div>

                <div class="mt-3" v-if="serviceDetails">
                    <div class="fw-bold fs-5 lh-1">Services Offered</div>
                    <div style="color: lightslategray; font-size: 12px;"><span>{{ serviceDetails.services.category }} > <span>{{ serviceDetails.services.sub_category }}</span></span></div>
                    <div class="d-flex flex-row justify-content-between align-items-center mt-2" v-for="(item, index) in serviceDetails.services.description">
                        <div>{{ item }}</div>
                        <div>
                            <span class="fw-bold text-warning">
                                <mdicon name="check" size="20"/>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="mt-3" v-if="serviceDetails">
                    <div class="fw-bold fs-5">Contact Numbers</div>
                    <div class="d-flex flex-row justify-content-between mt-1" v-for="(service, index) in serviceDetails.contact_number">
                        <div><mdicon name="phone-classic" size="16" class="me-3 align-items-center"/>{{ service.contact_no }}</div>
                        <div>
                            <span class="fw-bold text-info">
                                {{ service.label }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </Transition>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Pagination, Autoplay } from 'swiper/modules'
import { ref, onBeforeMount, computed, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import getServiceDetail from '@/composables/services/getServiceDetail'

import 'swiper/css'
import 'swiper/css/pagination'

export default {
    name: "ServiceDetails",
    components: {
        Swiper,
        SwiperSlide
    },
    setup() {
        const router = useRouter()
        const route = useRoute()
        const isExpanded = ref(true)
        const displayedText = ref('')
        const firstSentence = "The purpose of lorem ipsum is to create a natural looking block of text (sentence, paragraph, page, etc.) that doesn't distract from the layout. A practice not without controversy, laying out pages with meaningless filler text can be very useful when the focus is meant to be on design, not content."
        const secondSentence = "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book"
        const isCollapsed = ref(false)
        const serviceDetails = ref(null)
        const showBackIcon = ref(true)
        const baseUrl = process.env.VUE_APP_BACKEND_API + '/logo/'

        const swiperText = ref ([
            {
                img: require('@/assets/coffee.jpg')
            },
            {
                img: require('@/assets/door.jpg')
            },
            {
                img: require('@/assets/teaching-services.png')
            },
            {
                img: require('@/assets/lamp.jpg')
            },
        ])

        onBeforeMount(async() => {
            const {response, error} = await getServiceDetail(route.params.id)
            if (error.value === null) {
                serviceDetails.value = response.value
            }
        })
        
        onMounted(() => {
            collapseText(50)
        })

        const expandText = () => {
            displayedText.value = firstSentence
            isCollapsed.value = !isCollapsed.value
        }

        const collapseText = (textSize) => {
            displayedText.value = firstSentence.slice(0, textSize)
            isCollapsed.value = !isCollapsed.value
        }

        const onScroll = ({target: {scrollTop, clientHeight, scrollHeight}}) => {
            if (scrollTop > 100) {
                showBackIcon.value = false
            } else {
                showBackIcon.value = true
            }
        }
        return {
            router,
            isExpanded,
            firstSentence,
            secondSentence,
            expandText,
            collapseText,
            isCollapsed,
            displayedText,
            serviceDetails,
            onScroll,
            showBackIcon,
            baseUrl,
            modules: [Pagination, Autoplay],
            swiperText
        }

    }
}
</script>

<style scoped>

.back-menu {
    position: absolute;
}
.details-container { 
    height: 100%;
    overflow: scroll;
}
.details-inner {
    height: 100%;
    font-family: Arial, Helvetica, sans-serif;
}
.details-container::-webkit-scrollbar {
    display: none;
}
.slide-enter-active,
.slide-leave-active {
    transition: all 0.6s ease;
}

.slide-enter-from,
.slide-leave-to {
    opacity: 0;
    transform: translateX(60px);
}
</style>