<template>
    <form @submit.prevent="updateInfo">
        <div class="card border-0 home-container">
            <div class="card-body home-inner">
                <BackOnly />

                <div class="row mb-4">
                    <div class="col-md-2"></div>
                    <div class="col-md-8 d-flex flex-column align-items-center mt-3 text-center">
                        <img src="@/assets/sketchbook.png" width="70"/>
                        <span class="fw-bold fs-4">Register Your Service</span>
                        <span class="text-secondary" style="font-size: 12px">Please complete the registration below.  Approval of registration will take around 24-48 hours.</span>
                    </div>
                    <div class="col-md-2"></div>
                </div>

                <div class="row mt-4">
                    <div class="col-md-2"></div>
                    <div class="col-md-8 d-flex flex-column justify-content-start">
                        <span style="font-size: 14px; text-align: left;">Please upload a photo of your Store or your Logo</span>
                        <span style="font-size: 12px; text-align: left;">Maximum file size should not be greater than 1.0MB</span>
                        <FileUpload @file-updated="captureStorePhoto($event)" :hasError="hasError" :errorMsg="errorMsg" />
                    </div>
                    <div class="col-md-2"></div>
                </div>

                <div class="row mt-4 mb-4">
                    <div class="col-md-2"></div>
                    <div class="col-md-8 d-flex flex-row justify-content-center">
                        <button type="submit" class="btn btn-secondary shadow-sm w-100">Submit</button>
                    </div>
                    <div class="col-md-2"></div>
                </div>
            </div>
        </div>
    </form>
    <Loading v-if="loading"/>
</template>

<script>
import FileUpload from '@/components/FileUpload.vue'
import BackOnly from '@/components/Navigation/BackOnly.vue'
import Loading from '@/components/Loading.vue'
import { ref } from 'vue'
import uploadPhotos from '@/composables/services/uploadPhotos'
import { useRoute, useRouter } from 'vue-router'

export default {
    name: "AddServices2",
    setup() {
        const hasError = ref(false)
        const errorMsg = ref('')
        const loading = ref(false);
        const route = useRoute()
        const router = useRouter()
        const token = localStorage.getItem('token')
        const storePhoto = ref({
            id: '',
            logo: null
        })
        const captureStorePhoto = ($event) => {
            hasError.value = false
            storePhoto.value.logo = $event
        }

        storePhoto.value.id = route.params.id
        const updateInfo = async() => {
            loading.value = true
            const { response, error } = await uploadPhotos(storePhoto.value, token)
            loading.value = false
            if (error.value == null) {
                if (response.value.status > 201) {
                    hasError.value = true
                    errorMsg.value = response.value.message.message
                } else {
                    router.push("/")
                }
            }
        }
        return {
            loading,
            storePhoto,
            captureStorePhoto,
            updateInfo,
            hasError,
            errorMsg
        };
    },
    components: { 
        FileUpload,
        BackOnly,
        Loading
    }
}
</script>

<style scoped>
</style>