<template>
<div class="d-flex flex-row justify-content-start align-items-center mt-3" style="cursor: pointer;">
    <div><img :src="getImg(imgName)" width="50"/></div>
    <div class="ms-3 lh-1">
        <span class="fw-bold fs-5">{{category}}</span><br>
        <span class="text-secondary" style="font-size: 13px;">{{ summary }}</span>
    </div>
</div>
</template>

<script>

export default {
    name: "Category",
    props: {
        imgName: {
            type: String,
            default: ''
        },
        category: {
            type: String,
            default: ''
        },
        summary: {
            type: String,
            default: ''
        }
    },
    setup() {
        const getImg = (img) => {
            return require('@/assets/categories/'+img)
        }

        return {
            getImg
        }
    }

}
</script>

<style>
</style>