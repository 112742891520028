<template>
    <div class="card border-0 home-container">
        <div class="card-body home-inner">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8  d-flex flex-row justify-content-between align-items-center">
                    <div class="d-flex flex-row justify-content-start align-items-center">
                        <span class="fw-bold fs-5">Categories</span>
                    </div>
                    <div> <mdicon name="magnify" @click="router.push('/search')" size="30"/></div>
                </div>
                <div class="col-md-2"></div>
            </div>

            <div class="row mt-3">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <ul class="nav nav-underline nav-fill justify-content-center" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active text-dark" id="categories-tab" data-bs-toggle="tab" data-bs-target="#categories" type="button" role="tab" aria-controls="categories" aria-selected="true">Categories</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link text-dark" id="interest-tab" data-bs-toggle="tab" data-bs-target="#interest" type="button" role="tab" aria-controls="interest" aria-selected="false" disabled>Interest</button>
                        </li>
                    </ul>

                    <div class="tab-content" id="myTabContent">
                        <!-- Categories Tab Content -->
                        <div class="tab-pane fade show active" id="categories" role="tabpanel" aria-labelledby="categories-tab">
                            <div class="row mt-2" v-for="(item, index) in categories">
                                <!-- <div class="col-md-8" @click="router.push('/subCategories/' + item.imgName + '/' + item.category + '/' + item.summary)"> -->
                                <div class="col-md-8 ripple" @click="gotoSubcategories(item.imgName, item.category, item.summary)">
                                    <Category :imgName=item.imgName :category=item.category :summary=item.summary />
                                </div>
                            </div>
                        </div>

                        <!-- Interest Tab Content -->
                        <div class="tab-pane fade" id="interest" role="tabpanel" aria-labelledby="interest-tab">...</div>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>

        </div>
        <!-- Loading Modal -->
        <Loading v-if="loading"/>
    </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import Modal from '@/components/Modal.vue'
import Loading from '@/components/Loading.vue'
import store from '@/store'
import Category from '@/components/Category/Category.vue'
import CategoryEnums from '@/enums/CategoryEnum'

export default {
    name: "Categories",
    components: {
        Modal,
        Loading,
        Category
    },
    setup() {
        const router = useRouter()
        const loading = ref(false)

        // Categories are hard coded, Add array here if a new Main category is added.
        const categories = ref([
            { imgName: 'cpu.png', category: CategoryEnums.ELECTRONIC_REPAIR, summary: 'Radio, TV, Refrigerator, etc.'},
            { imgName: 'hook.png', category: CategoryEnums.CONTRACTORS, summary: 'Welding, Masonry, Painting and others.'},
            { imgName: 'maintenance.png', category: CategoryEnums.CARS_MAINTENANCE, summary: 'Vulcanizing, Car Rentals, Mehanics'},
            { imgName: 'teach.png', category: CategoryEnums.TEACHING_SERVICES, summary: 'Tutorial for Academics or Instruments'},
            { imgName: 'cosmetics.png', category: CategoryEnums.HAIR_AND_BODY, summary: 'Hair and makeup, manicure, pedicure, masasage.'},
            { imgName: 'cooperation.png', category: CategoryEnums.BUSINESS, summary: 'Bookeeping'},
            { imgName: 'photo.png', category: CategoryEnums.PHOTOGRAPHY, summary: 'Wedding and Parties, Debut.'},
            { imgName: 'vehicle.png', category: CategoryEnums.MEDICAL_SERVICES, summary: 'Dentail Clinic, Dialysis Center, Veterinary Clinic.'},
            { imgName: 'house-painting.png', category: CategoryEnums.HOUSE_SERVICES, summary: 'Aircon Cleaning, Landscaping, Sewing, Upholstery'}
        ])

        const gotoSubcategories = (imgName, category, summary) => {
            setTimeout(() => {
                router.push('/subCategories/' + imgName + '/' + category + '/' + summary)
            }, 400)
        }

        return {
            router,
            loading,
            categories,
            gotoSubcategories
        }
    }
}
</script>

<style scoped>

.home-inner {
    font-family: Arial, Helvetica, sans-serif;
}
.nav .nav-item .active {
    color: #F39C12 !important;
}
</style>