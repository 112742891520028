import { ref } from 'vue'

const approveServiceRequest = async(serviceId, token) => {
    const response = ref(null)
    const error = ref(null)

    const bearer = "Bearer " + token
    try {
        const reqOptions = {
            method: "PUT",
            headers: {"Content-Type":"application/json", "Authorization":bearer}
        }
        const data = await fetch(process.env.VUE_APP_BACKEND_API + '/api/v1/services/' + serviceId + '/approve', reqOptions)
        response.value = await data.json()

    } catch (err) {
        error.value = err
    }
    return { response, error }
}

export default approveServiceRequest