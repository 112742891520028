<template>
    <div class="card border-0 home-container">
        <div class="card-body home-inner">
            <BackOnly />

            <div class="row mb-4">
                <div class="col-md-2"></div>
                <div class="col-md-8 d-flex flex-column align-items-center mt-3 text-center">
                    <img src="@/assets/information.png" width="70"/>
                    <span class="fw-bold fs-4">Registered Services</span>
                    <span class="text-secondary" style="font-size: 12px">These are the list of your registered Services.</span>
                </div>
                <div class="col-md-2"></div>
            </div>

            <div class="row mt-2" v-for="(item, index) in myServices" style="cursor: pointer;">
                <div class="col-md-2"></div>
                <div class="col-md-8 view-container">
                    <div class="border rounded-start shadow">
                        <img :src="baseUrl + item.logo" class="w-100 border rounded-start" height="150" v-if="item.logo"/>
                        <img src="@/assets/imgPlaceholder.png" class="w-100 border rounded-start" height="150" v-else/>
                    </div>
                    <div class="p-2 border rounded-end shadow" style="font-size: 13px; height: 150px; width: 100%; position: relative;">
                        <div class="d-flex flex-row justify-content-between mt-2">
                            <div>
                                <span class="item-status" v-if="item.status === 0" style="background-color: lightgoldenrodyellow;">Pending</span>
                                <span class="item-status" v-else-if="item.status === 1" style="background-color: lightgreen;">Approved</span>
                                <span class="item-status"  v-else style="background-color: lightcoral;">Declined</span>
                            </div>
                            <div>
                                <mdicon name="check" size="18"/>
                            </div>
                        </div>
                        <div class="mt-2">
                            <span class="fw-bold text-success">{{ item.store_name }}</span><br>
                            <span style="font-size: 13px;">{{ item.address.address }}</span>
                            <!-- <span style="font-size: 13px; color: lightslategray">{{ item.services.sub_category }}</span> -->
                        </div>
                        <div class="edit d-flex justify-content-end mb-2 me-2">
                            <span class="item-status text-light" style="background-color: lightslategray;" @click="router.push('/editServices/' + item.id)">Edit</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>
        </div>
        <!-- Loading Modal -->
        <Loading v-if="loading"/>

    </div>
</template>

<script>
import { ref, onBeforeMount } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import getMyServices from '@/composables/services/getMyServices'
import Loading from '@/components/Loading.vue'
import store from '@/store'
import BackOnly from '@/components/Navigation/BackOnly.vue'

export default {
    name: "ListServices",
    components: {
    Loading,
    BackOnly
},
    setup () {
        const router = useRouter()
        const route = useRoute()
        const userToken = localStorage.getItem('token')
        const myServices = ref({})
        const loading = ref(true)
        const baseUrl = process.env.VUE_APP_BACKEND_API + '/logo/'

        onBeforeMount (async() => {
            if (!store.state.isUserLoggedIn) {
                router.push('/login')
            }
            loading.value = true
            const {response, error} = await getMyServices(userToken)
            loading.value = false
            if (error.value === null) {
                myServices.value = response.value
                console.log("my Services: ", myServices.value)
            }
        })

        return {
            router,
            myServices,
            loading,
            baseUrl
        }
    }
}
</script>

<style scoped>
.view-container {
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: space-between;
}

.item-status {
    padding: 5px;
    border-radius: 3px;
}

.edit {
    position: absolute;
    bottom: 0;
    right: 0;
}
</style>