<template>
    <Transition name="slide" appear>
    <div class="card border-0 home-container">
        <div class="card-body home-inner">
            <BackMagnify />

            <div class="row mb-4">
                <div class="col-md-2"></div>
                <div class="col-md-8 d-flex flex-column align-items-center mt-3">
                    <img :src="getImg(route.params.iconName)" width="70"/>
                    <span class="fw-bold fs-4">{{ route.params.category }}</span>
                    <span class="text-secondary" style="font-size: 14px">{{ route.params.summary }}</span>
                </div>
                <div class="col-md-2"></div>
            </div>

            <div class="row ripple" v-for="(item, index) in subCategories" @click="gotoCategoryResults(item)" style="cursor: pointer;">
                <SubCategory :description="item" />
            </div>

        </div>
        <!-- Loading Modal -->
        <Loading v-if="loading"/>
    </div>
    </Transition>
</template>

<script>
import { ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import Modal from '@/components/Modal.vue'
import Loading from '@/components/Loading.vue'
import SubCategory from '@/components/Category/SubCategory.vue'
import { 
    ContractorsEnum,
    ElectronicEnum,
    TeachingServicesEnum,
    MusicalArtsEnum,
    CarsMaintenaceEnum,
    HouseServicesEnum,
    HairAndBodyEnums,
    PhotographyEnums,
    BusinessEnums,
    MedicalServicesEnums
} from '@/enums/SubCategoryEnum'
import CategoryEnum from '@/enums/CategoryEnum'
import BackMagnify from '@/components/Navigation/BackMagnify.vue'

export default {
    name: "SubCategories",
    components: {
    Modal,
    Loading,
    SubCategory,
    BackMagnify
},
    setup() {
        const router = useRouter()
        const route = useRoute()
        const loading = ref(false)
        const subCategories = ref(null)

        if (route.params.category === CategoryEnum.CONTRACTORS) {
            subCategories.value = ContractorsEnum
        } else if (route.params.category === CategoryEnum.ELECTRONIC_REPAIR) {
            subCategories.value = ElectronicEnum
        }else if (route.params.category === CategoryEnum.TEACHING_SERVICES) {
            subCategories.value = TeachingServicesEnum
        }else if (route.params.category === CategoryEnum.CARS_MAINTENANCE) {
            subCategories.value = CarsMaintenaceEnum
        }else if (route.params.category === CategoryEnum.HOUSE_SERVICES) {
            subCategories.value = HouseServicesEnum
        }else if (route.params.category === CategoryEnum.HAIR_AND_BODY) {
            subCategories.value = HairAndBodyEnums
        }else if (route.params.category === CategoryEnum.PHOTOGRAPHY) {
            subCategories.value = PhotographyEnums
        }else if (route.params.category === CategoryEnum.BUSINESS) {
            subCategories.value = BusinessEnums
        }else if (route.params.category === CategoryEnum.MEDICAL_SERVICES) {
            subCategories.value = MedicalServicesEnums
        }

        // For some reason the image source does not load if we directly use the path + iconName.
        const getImg = (img) => {
            return require('@/assets/categories/'+img)
        }

        const gotoCategoryResults = (sub) => {
            setTimeout(() => {
                router.push('/categoryResults/' + sub)
            }, 400)
        }
        return {
            router,
            loading,
            route,
            subCategories,
            getImg,
            gotoCategoryResults
        }
    }
}
</script>

<style scoped>
.history-container::-webkit-scrollbar {
    display: none;
}
.slide-enter-active,
.slide-leave-active {
    transition: all 0.6s ease;
}

.slide-enter-from,
.slide-leave-to {
    opacity: 0;
    transform: translateX(60px);
}
</style>