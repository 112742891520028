import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '@/views/Home.vue'

// Services
import AddServices from '@/views/Services/AddServices.vue'
import AddServices2 from '@/views/Services/AddServices2.vue'
import ServiceDetails from '@/views/Services/ServiceDetails.vue'
import ListServices from '@/views/Services/ListServices.vue'

// Auth 
import Login from '@/views/Auth/Login.vue'
import Register from '@/views/Auth/Register.vue'

// Service Requests
import Pending from '@/views/ServiceRequests/Pending.vue'
import Approved from '@/views/ServiceRequests/Approved.vue'
import Declined from '@/views/ServiceRequests/Declined.vue'
import Requests from '@/views/ServiceRequests/Requests.vue'

// Search
import Search from '@/views/Search/Search.vue'
import SearchResults from '@/views/Search/SearchResults.vue'

// Categories
import Categories from '@/views/Categories/Categories.vue'
import SubCategories from '@/views/Categories/SubCategories.vue'
import CategoryResults from '@/views/Categories/CategoryResults.vue'

// Account
import AccountDetails from '@/views/Account/AccountDetails.vue'
import Account from '@/views/Account/Account.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/search',
    name: 'search',
    component: Search
  },
  {
    path: '/searchResults/:word',
    name: 'searchResults',
    component: SearchResults
  },
  {
    path: '/categoryResults/:word',
    name: 'categoryResults',
    component: CategoryResults
  },
  {
    path: '/categories',
    name: 'categories',
    component: Categories
  },
  {
    path: '/subCategories/:iconName/:category/:summary',
    name: 'subCategories',
    component: SubCategories
  },
  {
    path: '/addServices',
    name: 'addServices',
    component: AddServices
  },
  {
    path: '/addServices2/:id',
    name: 'addServices2',
    component: AddServices2
  },
  {
    path: '/editServices/:id',
    name: 'editServices',
    component: AddServices
  },
  {
    path: '/listServices',
    name: 'listServices',
    component: ListServices
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/serviceDetails/:id',
    name: 'serviceDetails',
    component: ServiceDetails
  },
  {
    path: '/requests',
    name: 'requests',
    component: Requests
  },
  {
    path: '/pending',
    name: 'pending',
    component: Pending
  },
  {
    path: '/approved',
    name: 'approved',
    component: Approved
  },
  {
    path: '/declined',
    name: 'declined',
    component: Declined
  },
  {
    path: '/account',
    name: 'account',
    component: Account
  },
  {
    path: '/accountDetails',
    name: 'accountDetails',
    component: AccountDetails
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
