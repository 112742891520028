import { ref } from 'vue'

const addEntry = async(tags, storeInfo, services, contactNo, category, token) => {
    const response = ref(null)
    const error = ref(null)

    const bearer = "Bearer " + token
    try {
        const reqOptions = {
            method: "POST",
            headers: {"Content-Type":"application/json", "Authorization":bearer},
            body: JSON.stringify({
                storeInfo: storeInfo,
                services: services,
                contactNo: contactNo,
                tags: tags,
                category: category
            })
        }
        // const data = await fetch("http://localhost:5000/services", reqOptions)
        const data = await fetch(process.env.VUE_APP_BACKEND_API + '/api/v1/services/', reqOptions)
        response.value = await data.json()

    } catch (err) {
        error.value = err
    }
    return { response, error }
}

export default addEntry