import { ref } from 'vue'

const getServiceDetail = async(id) => {
    const response = ref(null)
    const error = ref(null)

    try {
        const reqOptions = {
            method: "GET",
            headers: {"Content-Type":"application/json"}
        }
        // const data = await fetch("http://localhost:5000/services", reqOptions)
        const data = await fetch(process.env.VUE_APP_BACKEND_API + '/api/v1/services/' + id, reqOptions)
        response.value = await data.json()

    } catch (err) {
        error.value = err
    }
    return { response, error }
}

export default getServiceDetail