<template>
    <Transition name="slide" appear>
    <div class="card border-0 home-container">
        <div class="card-body home-inner">
            <BackMagnify />

            <div class="row mt-3">
                <div class="col-md-2"></div>
                <div class="col-md-8 ">
                    <span class="fw-bold fs-5">{{ searchText }}</span>
                </div>
                <div class="col-md-2"></div>
            </div>

            <div class="row mt-4">
                <div class="col-md-2"></div>
                <div class="col-md-8 ">
                    <span class="fw-bold">Filter by</span>
                    <div class="d-flex flex-row justify-content-start">
                        <button type="button" class="btn border rounded-pill me-2 shadow-sm">City</button>
                        <button type="button" class="btn border rounded-pill me-2 shadow-sm">Barangay</button>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>


            <div class="row mt-2" v-for="(item, index) in filteredData">
                <div class="col-md-2"></div>
                <ServiceDisplay :storeName="item.store_name" :address="item.address.address" :serviceId="item.id" :logo="item.logo" />
                <div class="col-md-2"></div>
            </div>
        </div>
        <!-- Loading Modal -->
        <Loading v-if="loading"/>
    </div>
    </Transition>
</template>

<script>
import { ref, onBeforeMount } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import Modal from '@/components/Modal.vue'
import search from '@/composables/search'
import Loading from '@/components/Loading.vue'
import BackMagnify from '@/components/Navigation/BackMagnify.vue'
import ServiceDisplay from '@/components/ServiceDisplay.vue'

export default {
    name: "Home",
    components: {
    Modal,
    Loading,
    BackMagnify,
    ServiceDisplay
},
    setup() {
        const modalActive = ref(false)
        const searchText = ref('')
        const filteredData = ref({})
        const router = useRouter()
        const route = useRoute()
        const loading = ref(false)

        searchText.value = route.params.word

        onBeforeMount (async() => {
            loading.value = true
            // Searching is done on the backend, this is more efficient with large data.
            const {response, error} = await search(searchText.value)
            loading.value = false
            if (error.value === null) {
                filteredData.value = response.value.results
            }
        })

        return {
            router,
            modalActive,
            searchText,
            filteredData,
            loading
        }
    }
}
</script>

<style scoped>

.view-container {
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: space-between;
}
.slide-enter-active,
.slide-leave-active {
    transition: all 0.6s ease;
}

.slide-enter-from,
.slide-leave-to {
    opacity: 0;
    transform: translateX(-60px);
}
</style>