import { ref } from 'vue'

const uploadPhotos = async(photos, token) => {
    const response = ref(null)
    const error = ref(null)

    console.log("photos: ", photos)
    const fd = new FormData()

    if (photos.logo !== null && photos.logo != "") {
        fd.append("logo", photos.logo)
    }

    const bearer = "Bearer " + token
    try {
        const reqOptions = {
            method: "PUT",
            headers: {"Authorization":bearer},
            body: fd
        }
        // const data = await fetch("http://localhost:5000/services", reqOptions)
        const data = await fetch(process.env.VUE_APP_BACKEND_API + '/api/v1/services/' + photos.id + '/upload', reqOptions)
        response.value = await data.json()

    } catch (err) {
        error.value = err
    }
    return { response, error }
}

export default uploadPhotos