<template>
<div class="col-md-2"></div>
<div class="col-md-8">
    <div class="d-flex flex-row justify-content-between align-items-center">
        <div class="p-2 d-flex flex-row justify-content-between">
            <span class="" >{{description}}</span>
        </div>
        <div><mdicon name="chevron-right" size="30"/></div>
    </div>
</div>
<div class="col-md-2"></div>
</template>

<script>

export default {
    name: "SubCategory",
    props: {
        description: {
            type: String,
            default: ''
        }
    },
    setup() {

    }

}
</script>

<style>

</style>