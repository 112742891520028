<template>
<AccountTop />
<div class="card border-0">
    <div class="card-body">
        <div class="row" v-if="!store.state.isUserLoggedIn" @click="router.push('/login')"> <AccountMenu menu="Sign in" iconName="login"/> </div>

        <div class="row mt-3">
            <div class="col-md-12 fw-bold fs-5">
                <span>General</span>
            </div>
        </div>
        <div class="row"><AccountMenu menu="My interest" iconName="medal-outline"/></div>


        <div v-if="store.state.isUserLoggedIn">
            <div class="row mt-3">
                <div class="col-md-12 fw-bold fs-5">
                    <span>Settings</span>
                </div>
            </div>
            <div class="row"><AccountMenu menu="Preferences" iconName="cog-outline"/></div>
            <div class="row ripple" @click="gotoAccountDetails"><AccountMenu menu="Account" iconName="account-cog-outline"/></div>
        </div>


        <div class="row mt-3">
            <div class="col-md-12 fw-bold fs-5">
                <span>Resources</span>
            </div>
        </div>
        <div class="row"><AccountMenu menu="Become a service dealer" iconName="archive-star-outline"/></div>
        <div class="row"><AccountMenu menu="Support" iconName="lifebuoy"/></div>
        <div class="row"><AccountMenu menu="Privacy Policy" iconName="lock-outline"/></div>

    </div>

</div>
</template>

<script>
import { provide } from 'vue'
import { useRouter } from 'vue-router'
import store from '@/store'
import AccountTop from '@/components/Accounts/AccountTop.vue'
import AccountMenu from '@/components/Accounts/AccountMenu.vue'

export default {
    name: "Account",
    components: {
        AccountTop,
        AccountMenu
    },
    setup () {
        const router = useRouter()
        provide('store', store)

        const gotoAccountDetails = () => {
            setTimeout(() => {
                router.push('/accountDetails')
            }, 400)
        }
        return {
            store,
            router,
            gotoAccountDetails
        }
    }
}
</script>

<style scoped>
.profile {
    display: grid;
    grid-template-columns: 30% 70%;
    justify-content: space-between;
}
</style>