<template>
    <form @submit.prevent="saveInfo">
    <div class="card border-0 home-container">
        <div class="card-body home-inner">
            <BackOnly />

            <div class="row mb-4">
                <div class="col-md-2"></div>
                <div class="col-md-8 d-flex flex-column align-items-center mt-3 text-center">
                    <img src="@/assets/sketchbook.png" width="70"/>
                    <span class="fw-bold fs-4">{{ title }}</span>
                    <span class="text-secondary" style="font-size: 12px">Please complete the registration below.  Approval of registration will take around 24-48 hours.</span>
                </div>
                <div class="col-md-2"></div>
            </div>

            <div class="row mt-4">
                <div class="col-md-2"></div>
                <div class="col-md-8 d-flex flex-column justify-content-start">
                    <span style="font-size: 12px; text-align: left;">What is your name or name of your business?</span>
                    <input type="text" class="service-form form-control lh-1" placeholder="" v-model="personalInfo.name" required/>
                </div>
                <div class="col-md-2"></div>
            </div>
            <div class="row mt-3">
                <div class="col-md-2"></div>
                <div class="col-md-8 d-flex flex-column justify-content-start">
                    <span style="font-size: 12px; text-align: left;">What is your exact address?</span>
                    <input type="text" class="service-form form-control lh-1" placeholder="" v-model="personalInfo.address" required/>
                </div>
                <div class="col-md-2"></div>
            </div>
            <div class="row mt-3">
                <div class="col-md-2"></div>
                <div class="col-md-4 d-flex flex-column justify-content-start">
                    <span style="font-size: 12px; text-align: left;">Which City?</span>
                    <input type="text" class="form-control service-form" placeholder="" v-model="personalInfo.city" required/>
                </div>
                <div class="col-md-4 d-flex flex-column justify-content-start mt-3">
                    <span style="font-size: 12px; text-align: left;">Which Barangay?</span>
                    <input type="text" class="form-control service-form" placeholder="" v-model="personalInfo.barangay" required/>
                </div>
                <div class="col-md-2"></div>
            </div>
            <div class="row mt-3">
                <div class="col-md-2"></div>
                <div class="col-md-8 d-flex flex-column justify-content-start">
                    <span style="font-size: 12px; text-align: left;">What is your contact number? </span>
                    <div class="d-flex flex-row justify-content-start" v-for="(item, index) in contactNo">
                        <div class="input-group mt-1 ms-1">
                            <input type="text" class="form-control" placeholder="label" list="labels" style="max-width: 100px;" v-model="item.label" required/>
                            <datalist id="labels">
                                <option>Work</option>
                                <option>Home</option>
                                <option>Mobile</option>
                                <option>Main</option>
                                <option>Other</option>
                            </datalist>
                            <input type="tel" class="form-control" placeholder="" v-model="item.contact_no" @input="formattedContactNum(item.contact_no, index)" maxlength="13" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}|[0-9]{4}-[0-9]{3}-[0-9]{4}" required/>
                            <button class="btn btn-danger btn-sm" type="button" @click="removeContactRow(index)" ><mdicon name="minus" size="15"/></button>
                            <button class="btn btn-secondary btn-sm" type="button" @click="addContactRow" ><mdicon name="plus" size="15"/></button>
                        </div>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>
            <div class="row mt-3">
                <div class="col-md-2"></div>
                <div class="col-md-8 d-flex flex-column justify-content-start">
                    <span style="font-size: 12px; text-align: left;">Select the service category below </span>
                    <div class="input-group mt-1" >
                        <select class="form-select form-select service-form" v-model="category.categorySelected" @change="setSubCategories">
                            <option value="Select" selected><span class="text-warning" style="font-size: 13px;">Select Category</span></option>
                            <option :value="item" v-for="(item, index) in CategoryEnum">{{ item }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>
            <div class="row mt-1">
                <div class="col-md-2"></div>
                <div class="col-md-8 d-flex flex-column justify-content-start">
                    <div class="input-group mt-1" >
                        <select class="form-select form-select service-form" v-model="category.subCategorySelected" @change="setSubCategoryLists()">
                            <option value="Select" selected>Select Sub Category</option>
                            <option :value="item" v-for="(item, index) in subCategories">{{ item }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>
            <div class="row mt-3" v-if="serviceLists">
                <div class="col-md-2"></div>
                <div class="col-md-8 d-flex flex-column justify-content-start" style="font-size: 14px">
                    <span style="font-size: 12px; text-align: left;">Please check below which services do you offer. </span>
                    <div class="input-group mt-1" v-for="(item, index) in serviceLists">
                        <input type="checkbox" class="form-check-input shadow-sm" :value="item" v-model="servicesOffered" placeholder=""/>
                        <label class="form-check-label ms-2">
                            {{ item }}
                        </label>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>

            <!-- <div class="row mt-1">
                <div class="col-md-2"></div>
                <div class="col-md-8 d-flex flex-column justify-content-start">
                    <h6 class="mt-2" style="text-align: left;">Tags</h6>
                    <div class="input-group mt-1" v-for="(item, index) in tags">
                        <input type="text" class="form-control shadow-sm" placeholder="" v-model="item.description" required/>
                        <button class="btn btn-danger border border-0 text-dark shadow-sm" type="button" @click="removeTagRow(index)" style="background-color: #e4d0d0;"><mdicon name="minus"/></button>
                        <button class="btn btn-primary border border-0 text-dark shadow-sm" type="button" @click="addTagRow" style="background-color: #c2d8dc;"><mdicon name="plus"/></button>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div> -->
            <div class="row mt-4 mb-4">
                <div class="col-md-2"></div>
                <div class="col-md-8 d-flex flex-row justify-content-center">
                    <button type="submit" class="btn btn-secondary shadow-sm w-100">{{ cmd }}</button>
                </div>
                <div class="col-md-2"></div>
            </div>
        </div>
    </div>
    </form>
    <Loading v-if="loading"/>
</template>

<script>
import { ref, onBeforeMount } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import addEntry from '@/composables/services/addEntry'
import getServiceDetail from '@/composables/services/getServiceDetail'
import editEntry from '@/composables/services/editEntry'
import Loading from '@/components/Loading.vue'
import store from '@/store'
import BackOnly from '@/components/Navigation/BackOnly.vue'
import CategoryEnum from '@/enums/CategoryEnum'
import {
    ContractorsEnum,
    ElectronicEnum,
    CarsMaintenaceEnum,
    MedicalServicesEnums,
    TeachingServicesEnum,
    HairAndBodyEnums,
    BusinessEnums,
    PhotographyEnums,
    HouseServicesEnum
} from '@/enums/SubCategoryEnum'
import {
    GeneralContractorListsEnum,
    ApplianceRepairListsEnum,
    CarRepairListsEnum,
    CarWashListsEnum,
    SewingListsEnum,
    CarRentalListsEnum,
    DentalClinicListsEnum,
    AirconCleaningListsEnum,
    DecloggingListsEnum,
    UpholsteryListsEnum,
    MovingServicesListsEnum,
    AcademicTutorialListsEnum,
    InstrumentLessonListsEnum,
    CleaningServicesListsEnum,
    PestControlServicesListsEnum,
    TintAutoGlassServicesListsEnum
} from '@/enums/SubCategoryListsEnum'

export default {
    name: "AddServices",
    components: {
        Loading,
        BackOnly
    },
    setup() {
        const router = useRouter()
        const route = useRoute()
        const personalInfo = ref({name: '', address: '', city: '', barangay: ''})
        const servicesOffered = ref([])
        const contactNo = ref([{label: 'Mobile', contact_no: ''}])
        const userToken = localStorage.getItem('token')
        const tags = ref([{description: ''}])
        const isEdit = ref(false)
        const cmd = ref('Submit')
        const title = ref('Register Your Service')
        const loading = ref(false)
        const category = ref({
            categorySelected: 'Select',
            subCategorySelected: 'Select',
        })
        const subCategories = ref (null)
        const serviceLists = ref (null)

        onBeforeMount(async() => {
            // Redirect user to login 
            if (!store.state.isUserLoggedIn) {
                router.push('/login')
            }
            // This condition is when the user edit the service.
            if (route.params.id) {
                isEdit.value = true
                cmd.value = 'Edit'
                title.value = 'Edit Your Service'

                loading.value = true
                const { response, error } = await getServiceDetail(route.params.id)
                loading.value = false
                if (error.value === null) {
                    // Fillout the needed variables to be displayed on the page for editing.
                    personalInfo.value.name = response.value.store_name
                    personalInfo.value.address = response.value.address.address
                    personalInfo.value.city = response.value.address.city
                    personalInfo.value.barangay = response.value.address.barangay

                    contactNo.value = response.value.contact_number
                    servicesOffered.value = response.value.services.description
                    category.value.categorySelected = response.value.services.category
                    category.value.subCategorySelected = response.value.services.sub_category
                    // The two functions below will populate the list of category and sub category dropdown.
                    setSubCategories()
                    setSubCategoryLists()

                    if (Object.keys(response.value.tags).length > 0) {
                        tags.value = response.value.tags
                    }

                }
            }
        })
        // const addRow = () => {
        //     servicesOffered.value.push({description: ''})
        // }
        // const removeRow = (index) => {
        //     if (servicesOffered.value.length > 1) {
        //         servicesOffered.value.splice(index, 1)
        //     }
        // }

        // Add/remove Contactrow will populate the contact number field when pressing + / - button.
        const addContactRow = () => {
            contactNo.value.push({label: 'Mobile', contact_no: ''})
        }
        const removeContactRow = (index) => {
            if (contactNo.value.length > 1) {
                contactNo.value.splice(index, 1)
            }
        }
        const addTagRow = () => {
            tags.value.push({description: ''})
        }
        const removeTagRow = (index) => {
            if (tags.value.length > 1) {
                tags.value.splice(index, 1)
            }
        }

        const saveInfo = async() => {

            loading.value = true
            if (isEdit.value) {
                // This condition here is called when editing a service.
                const {response, error } = await editEntry(route.params.id, tags.value, personalInfo.value, servicesOffered.value, contactNo.value, category.value, userToken)
                loading.value = false
                if (error.value === null) {
                }
                router.push('/')
            } else {
                // This condition here is called when adding a service.
                const {response, error} = await addEntry(tags.value, personalInfo.value, servicesOffered.value, contactNo.value, category.value, userToken)
                loading.value = false
                if (error.value === null) {
                    console.log("response: ", response)
                    router.push('/addServices2/' + response.value.id)
                }
            }
        }

        const setSubCategories = () => {

            serviceLists.value = null

            if (category.value.categorySelected == CategoryEnum.CONTRACTORS) {
                subCategories.value = ContractorsEnum
            } else if (category.value.categorySelected == CategoryEnum.ELECTRONIC_REPAIR) {
                subCategories.value = ElectronicEnum
            } else if (category.value.categorySelected == CategoryEnum.CARS_MAINTENANCE) {
                subCategories.value = CarsMaintenaceEnum
            } else if (category.value.categorySelected == CategoryEnum.TEACHING_SERVICES) {
                subCategories.value = TeachingServicesEnum
            } else if (category.value.categorySelected == CategoryEnum.HAIR_AND_BODY) {
                subCategories.value = HairAndBodyEnums
            } else if (category.value.categorySelected == CategoryEnum.BUSINESS) {
                subCategories.value = BusinessEnums
            } else if (category.value.categorySelected == CategoryEnum.PHOTOGRAPHY) {
                subCategories.value = PhotographyEnums
            } else if (category.value.categorySelected == CategoryEnum.MEDICAL_SERVICES) {
                subCategories.value = MedicalServicesEnums
            } else if (category.value.categorySelected == CategoryEnum.HOUSE_SERVICES) {
                subCategories.value = HouseServicesEnum
            }
        }

        const setSubCategoryLists = () => {
            serviceLists.value = null
            if (category.value.subCategorySelected === Object.values(ElectronicEnum)[0]) {
                // Appliances Repair
                serviceLists.value = ApplianceRepairListsEnum
            } else if (category.value.subCategorySelected === Object.values(ContractorsEnum)[0]) {
                // General Contractor
                serviceLists.value = GeneralContractorListsEnum
            } else if (category.value.subCategorySelected == Object.values(CarsMaintenaceEnum)[0]) {
                serviceLists.value = CarRepairListsEnum
            } else if (category.value.subCategorySelected == Object.values(CarsMaintenaceEnum)[2]) {
                serviceLists.value = CarWashListsEnum
            } else if (category.value.subCategorySelected == Object.values(CarsMaintenaceEnum)[3]) {
                serviceLists.value = CarRentalListsEnum
            } else if (category.value.subCategorySelected == Object.values(CarsMaintenaceEnum)[5]) {
                serviceLists.value = TintAutoGlassServicesListsEnum
            } else if (category.value.subCategorySelected == Object.values(HouseServicesEnum)[3]) {
                serviceLists.value = UpholsteryListsEnum
            } else if (category.value.subCategorySelected == Object.values(HouseServicesEnum)[4]) {
                serviceLists.value = SewingListsEnum
            } else if (category.value.subCategorySelected == Object.values(HouseServicesEnum)[6]) {
                serviceLists.value = MovingServicesListsEnum
            } else if (category.value.subCategorySelected == Object.values(HouseServicesEnum)[7]) {
                serviceLists.value = CleaningServicesListsEnum
            } else if (category.value.subCategorySelected == Object.values(HouseServicesEnum)[8]) {
                serviceLists.value = PestControlServicesListsEnum
            } else if (category.value.subCategorySelected == Object.values(MedicalServicesEnums)[0]) {
                serviceLists.value = DentalClinicListsEnum
            } else if (category.value.subCategorySelected == Object.values(HouseServicesEnum)[0]) {
                serviceLists.value = AirconCleaningListsEnum
            } else if (category.value.subCategorySelected == Object.values(HouseServicesEnum)[1]) {
                serviceLists.value = DecloggingListsEnum
            } else if (category.value.subCategorySelected == Object.values(TeachingServicesEnum)[0]) {
                serviceLists.value = AcademicTutorialListsEnum
            } else if (category.value.subCategorySelected == Object.values(TeachingServicesEnum)[1]) {
                serviceLists.value = InstrumentLessonListsEnum
            } 
        }

        const formattedContactNum = (num, index) => {
            // Automatically add - after 4th and 8th character.
            if (num.length === 4 || num.length === 8) {
                contactNo.value[index].contact_no += '-'
            }
        }
        return {
            personalInfo,
            servicesOffered,
            tags,
            // addRow,
            // removeRow,
            saveInfo,
            addContactRow,
            removeContactRow,
            contactNo,
            addTagRow,
            removeTagRow,
            cmd,
            router,
            title,
            loading,
            category,
            setSubCategories,
            setSubCategoryLists,
            formattedContactNum,
            subCategories,
            serviceLists,
            CategoryEnum
        }
    }
}
</script>

<style scoped>

.service-form {
    box-shadow: none !important;
    border-radius: 0%;
    border: none;
    border-bottom: 1px inset;
}
</style>

