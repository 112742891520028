<template>
    <Transition name="slide" appear>
    <div class="card border-0 home-container">
        <div class="card-body home-inner">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8  d-flex flex-row justify-content-start align-items-center">
                    <mdicon name="chevron-left" @click="router.go(-1)" size="30"/>
                    <input type="text" class="form-control border border-0" placeholder="Search services" ref="inputFocus" v-model="searchKey" @keypress.enter="findServices"/>
                </div>
                <div class="col-md-2"></div>
            </div>

            <div class="row mt-3">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <div class="d-flex flex-row justify-content-between fw-bold">
                        <span>Search History</span>
                        <span class="text-danger">Clear History</span>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>

            <div class="row mt-2">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <div class="d-flex flex-row justify-content-between">
                        <span><mdicon name="clock-time-nine-outline" class="me-3"/>Contractor</span>
                        <span class="me-3"><mdicon name="arrow-top-left"/></span>
                    </div>
                    <div class="d-flex flex-row justify-content-between mt-2">
                        <span><mdicon name="clock-time-nine-outline" class="me-3"/>Piano Teacher</span>
                        <span class="me-3"><mdicon name="arrow-top-left"/></span>
                    </div>
                    <div class="d-flex flex-row justify-content-between mt-2">
                        <span><mdicon name="clock-time-nine-outline" class="me-3"/>Upholstery</span>
                        <span class="me-3"><mdicon name="arrow-top-left"/></span>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>


            <div class="row mt-3">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <div class="d-flex flex-row justify-content-start fw-bold">
                        <span>Recently viewed Services</span>
                    </div>
                    <div class="history-container mt-2" style="overflow: scroll;">
                        <div class="d-flex flex-row justify-content-start">
                            <div class="border rounded shadow-sm ms-2" style="display: inline-block; width: 170px; max-width: 170px;">
                                <img src="@/assets/door.jpg" width="150" height="100">
                                <div class="p-2" style="font-size: 13px; height: 100px; width: 150px;">
                                    <div class="d-flex flex-row justify-content-between">
                                        <div>
                                            <span class="align-items-middle">
                                                <mdicon name="star" size="18" style="color: goldenrod" />
                                                <span class="fw-bold ms-1">5.0</span>
                                                <span class="text-secondary ms-1">(4)</span>
                                            </span>
                                        </div>
                                        <div>
                                            <mdicon name="heart-outline" size="18"/>
                                        </div>
                                    </div>
                                    <div>
                                        Need a massage? I can do for free.
                                    </div>
                                    <div class="d-flex justify-content-end mt-1">
                                        <span class="text-secondary">from <span class="fw-bold">P50</span></span>
                                    </div>
                                </div>
                            </div>
                            <div class="border rounded shadow-sm ms-2" style="display: inline-block; width: 170px; max-width: 170px;">
                                <img src="@/assets/door.jpg" width="150" height="100">
                                <div class="p-2" style="font-size: 13px; height: 100px; width: 150px;">
                                    <div class="d-flex flex-row justify-content-between">
                                        <div>
                                            <span class="align-items-middle">
                                                <mdicon name="star" size="18" style="color: goldenrod" />
                                                <span class="fw-bold ms-1">5.0</span>
                                                <span class="text-secondary ms-1">(4)</span>
                                            </span>
                                        </div>
                                        <div>
                                            <mdicon name="heart-outline" size="18"/>
                                        </div>
                                    </div>
                                    <div>
                                        Need a massage? I can do for free.
                                    </div>
                                    <div class="d-flex justify-content-end mt-1">
                                        <span class="text-secondary">from <span class="fw-bold">P50</span></span>
                                    </div>
                                </div>
                            </div>
                            <div class="border rounded shadow-sm ms-2" style="display: inline-block; width: 170px; max-width: 170px;">
                                <img src="@/assets/door.jpg" width="150" height="100">
                                <div class="p-2" style="font-size: 13px; height: 100px; width: 150px;">
                                    <div class="d-flex flex-row justify-content-between">
                                        <div>
                                            <span class="align-items-middle">
                                                <mdicon name="star" size="18" style="color: goldenrod" />
                                                <span class="fw-bold ms-1">5.0</span>
                                                <span class="text-secondary ms-1">(4)</span>
                                            </span>
                                        </div>
                                        <div>
                                            <mdicon name="heart-outline" size="18"/>
                                        </div>
                                    </div>
                                    <div>
                                        Need a massage? I can do for free.
                                    </div>
                                    <div class="d-flex justify-content-end mt-1">
                                        <span class="text-secondary">from <span class="fw-bold">P50</span></span>
                                    </div>
                                </div>
                            </div>
                            <div class="border rounded shadow-sm ms-2" style="display: inline-block; width: 170px; max-width: 170px;">
                                <img src="@/assets/door.jpg" width="150" height="100">
                                <div class="p-2" style="font-size: 13px; height: 100px; width: 150px;">
                                    <div class="d-flex flex-row justify-content-between">
                                        <div>
                                            <span class="align-items-middle">
                                                <mdicon name="star" size="18" style="color: goldenrod" />
                                                <span class="fw-bold ms-1">5.0</span>
                                                <span class="text-secondary ms-1">(4)</span>
                                            </span>
                                        </div>
                                        <div>
                                            <mdicon name="heart-outline" size="18"/>
                                        </div>
                                    </div>
                                    <div>
                                        Need a massage? I can do for free.
                                    </div>
                                    <div class="d-flex justify-content-end mt-1">
                                        <span class="text-secondary">from <span class="fw-bold">P50</span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>
        </div>
        <!-- Loading Modal -->
        <Loading v-if="loading"/>
    </div>
    </Transition>
</template>

<script>
import { ref, onMounted, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import Modal from '@/components/Modal.vue'
import Loading from '@/components/Loading.vue'
import store from '@/store'

export default {
    name: "Search",
    components: {
        Modal,
        Loading
    },
    setup() {
        const searchKey = ref('')
        const router = useRouter()
        const loading = ref(false)
        const inputFocus = ref(null)

        onMounted(() => {
            // The purpose of this function is to automatically set the focus on the search input.
            nextTick(() => {
                inputFocus.value.focus()
            })
        })

        const findServices = () => {
            if (searchKey.value == '') {
                return
            }
            router.push('/searchResults/' + searchKey.value)
        }
        return {
            router,
            searchKey,
            loading,
            inputFocus,
            findServices
        }
    }
}
</script>

<style scoped>
.history-container::-webkit-scrollbar {
    display: none;
}
*:focus {
    box-shadow: none !important;
}

.slide-enter-active,
.slide-leave-active {
    transition: all 0.6s ease;
}

.slide-enter-from,
.slide-leave-to {
    opacity: 0;
    transform: translateY(250px)
}


</style>