const ContractorsEnum = Object.freeze({
    GENERAL_CONTRACTOR: "General Contractors"
})

const ElectronicEnum = Object.freeze({
    APPLIANCES_REPAIR: "Appliances Repair" 
})

const TeachingServicesEnum = Object.freeze({
    ACADEMIC: "Academic Tutorial",
    INSTRUMENTS: "Instruments Tutorial"
})

const CarsMaintenaceEnum = Object.freeze({
    CAR_REPAIR: "Car Repair",
    VULCANIZING: "Vulcanizing",
    CAR_WASH: "Car Wash",
    CAR_RENTAL: "Car Rentals",
    DRIVER: "Driver",
    TINT_AUTOGLASS: "Tint & Autoglass Services"
})
const HouseServicesEnum = Object.freeze({
    AIRCON_CLEANING: "Aircon Cleaning",
    DECLOGGING_SERVICES: "Declogging Services",
    LANDSCAPING: "Landscaping",
    UPHOLSTERY: "Upholstery",
    SEWING: "Sewing",
    SHOE_REPAIR: "Shoe Repair",
    MOVING_SERVICES: "Moving Services",
    CLEANING_SERVICES: "Cleaning Services",
    PEST_CONTROL_SERVICES: "Pest Control Services"
})


const HairAndBodyEnums = Object.freeze({
    HAIR_MAKEUP: "Hair and Makeup",
    MANICURE: "Manicure",
    PEDICURE: "Pedicure",
    MASSAGE: "Massage Therapist",
})
const PhotographyEnums = Object.freeze({
    WEDDING: "Wedding",
    DEBUT: "Debut",
    EVENTS: "Events",
    PRODUCT: "Product",
})
const BusinessEnums = Object.freeze({
    BOOKEEPING: "Bookeeping",
})

const MedicalServicesEnums = Object.freeze({
    DENTAL_CLINIC: "Dental Clinic",
    VETERINARY_CLINIC: "Veterinary Clinic",
    DIALYSIS_CENTER: "Dialysis Center",
    PEDIA_CLINIC: "Pediatric Clinic"
})

export {
    ContractorsEnum,
    ElectronicEnum,
    TeachingServicesEnum,
    CarsMaintenaceEnum,
    HouseServicesEnum,
    HairAndBodyEnums,
    PhotographyEnums,
    BusinessEnums,
    MedicalServicesEnums
}