<template>
    <Transition name="slide" appear>
    <div class="card border-0 home-container">
        <div class="card-body home-inner">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8  d-flex flex-row justify-content-between align-items-center">
                    <div class="d-flex flex-row justify-content-start align-items-center">
                        <mdicon name="chevron-left" @click="router.go(-1)" size="30"/>
                        <input type="text" class="form-control border border-0 w-100" v-model="searchText" @click="router.push('/search')"/>
                    </div>
                    <div> <mdicon name="magnify" @click="router.push('/search')" size="30"/></div>
                </div>
                <div class="col-md-2"></div>
            </div>

            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8 ">
                    <span>Showing results for "<span class="fw-bold text-success">{{ searchText }}</span>".</span>
                </div>
                <div class="col-md-2"></div>
            </div>

            <div class="row mt-4">
                <div class="col-md-2"></div>
                <div class="col-md-8 ">
                    <span class="fw-bold">Filter by</span>
                    <div class="d-flex flex-row justify-content-start">
                        <button type="button" class="btn border rounded-pill me-2 shadow-sm">City</button>
                        <button type="button" class="btn border rounded-pill me-2 shadow-sm">Barangay</button>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>


            <div class="row mt-2" v-for="(item, index) in filteredData" style="cursor: pointer;">
                <div class="col-md-2"></div>
                <ServiceDisplay :storeName="item.store_name" :address="item.address.address" :serviceId="item.id" :logo="item.logo"/>
                <div class="col-md-2"></div>
            </div>
        </div>
        <!-- Loading Modal -->
        <Loading v-if="loading"/>

    </div>
    </Transition>
</template>

<script>
import { ref, onBeforeMount } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import Modal from '@/components/Modal.vue'
import search from '@/composables/search'
import Loading from '@/components/Loading.vue'
import store from '@/store'
import ServiceDisplay from '@/components/ServiceDisplay.vue'

export default {
    name: "SearchResults",
    components: {
        Modal,
        Loading,
        ServiceDisplay
    },
    setup() {
        const modalActive = ref(false)
        const searchText = ref('')
        const filteredData = ref({})
        const router = useRouter()
        const route = useRoute()
        const loading = ref(false)
        const serviceId = ref(null)

        searchText.value = route.params.word

        onBeforeMount (async() => {
            loading.value = true
            const {response, error} = await search(searchText.value)
            loading.value = false
            if (error.value === null) {
                filteredData.value = response.value.results
            }
        })

        return {
            router,
            modalActive,
            searchText,
            filteredData,
            loading,
            serviceId
        }
    }
}
</script>

<style scoped>

.slide-enter-active,
.slide-leave-active {
    transition: all 0.6s ease;
}

.slide-enter-from,
.slide-leave-to {
    opacity: 0;
    transform: translateY(-250px)
}
</style>