const GeneralContractorListsEnum = Object.freeze({
    WELDERS: "Welders",
    MASONRY: "Masonry Works",
    CABINETRY: "Cabinetry",
    PLUMBING: "Plumbing",
    ELECTRICAL: "Electrical",
    ROFFING: "Roofing",
    STRUCTURAL: "Structural Works"
})
const ElectricalContractorListsEnum = Object.freeze({
    BUILDING_ELECTRICAL: "Building Electrical",
})


const ApplianceRepairListsEnum = Object.freeze({
    MOBILE: "Mobile",
    TELEVISION: "Television",
    REFRIGERATOR: "Refrigerator",
    AIRCON: "Aircon",
    WASHING_MACHINE: "Washing Machine",
    RADIO: "Radio",
    ELECTRIC_FAN: "Electric Fan",
    DRONE: "Drone Repair"
})

const CarRepairListsEnum = Object.freeze ({
    PMS_CHECKUP: "PMS Checkup",
    CAR_ELECTRICAL_SYSTEM: "Car Electrical System",
    ENGINE_TUNEUP: "Engine Tuneup",
    CHANGE_OIL: "Change oil lube and filter",
    BRAKE_TRANSMISSION: "Brake and Transmission Services",
    AIRCON_AND_EGR_CLEANING: "Aircon and EGR Cleaning",
    ALTERNATOR: "Alternator Repair",
    FUEL_PUMP_HOSE_TIMING_BELT: "Fuel Pump, Hose and Timing Belt Replacement",
    SUSPENSION: "Suspension Repair and Replacement",
    WHEEL_ALIGNMENT: "Wheel Alignment",
    NITROGEN_AIR: "Nitrogen Air",
})

const CarWashListsEnum = Object.freeze ({
    BODY_WASH: "Body Wash",
    ENGINE_CLEANING: "Engine Cleaning",
    INTERIOR_CLEANING: "Interior Cleaning",
    SEATCOVER_INSTALL: "Seat Cover Installation",
    UNDER_CHASSIS_CLEANING: "Under Chasis Cleaning",
    HEADLIGHT_RESTORATION: "Headlight Restoration",
    RAIN_WATER_MARKS_REMOVAL: "Rain Water marks Removal",
    MACHINE_BUFF: "Machine Buff",
    DEGRIMMING: "Degrimming",
    BODY_PAINTING: "Wash Over Paint"
})

const CarRentalListsEnum = Object.freeze({
    SEDAN: "Sedan",
    VAN: "Van",
    MULTICAB: "Multicab",
    SUV: "SUV",
    PICKUP: "Pickup"
})

const SewingListsEnum = Object.freeze({
    CURTAINS: "Curtains",
    DRESS: "Dress",
    SEATCOVER: "Seat Covers",
    TABLECOVERS: "Table Covers"

})

const DentalClinicListsEnum = Object.freeze({
    TOOTH_CLEANING: "Tooth Cleaning",
    FILLINGS: "Fillings (Pasta)",
    DENTURES: "Dentures (Pustiso)",
    CROWNS: "Crowns (Jacket) / Bridges",
    VENEERS: "Veneers",
    ROOT_CANAL: "Root Canal Treatment",
    TOOTH_WHITENING: "Tooth Whitening",
    EXTRACTION: "Extraction",
    DENTAL_XRAY: "Dental X-Ray"
})

const AirconCleaningListsEnum = Object.freeze({
    CLEANING: "Cleaning",
    INSTALLATION: "Installation (window/split)",
    REPAIR: "Repair",
    LEAK_FREON_CHARGING: "Leak Test / Freon Charging",
    DISMANTLE_REINSTALL: "Dismantle / Reinstallation"
})

const DecloggingListsEnum = Object.freeze({
    KITCHEN_SINK: "Sink",
    TOILETS: "Toilets",
    DRAINAGE: "Drainage",
    SEPTIC_RESERVE: "Septic Tank Reserve",
    
})

const UpholsteryListsEnum = Object.freeze({
    CAR_SEAT_COVER: "Car Seat Cover",
    SIDINGS: "Sidings",
    CARPET: "Carpet",
    MATTING: "Matting",
    CEILING: "Ceiling",
    STEERING_WHEEL: "Steering Wheel",
    PLASTIC_FLOORING: "Plastic Flooring",
    SOFA_LAZYBOY: "Sofa, Lazy Boy",
    CHAIRS: "Chairs (dining, Gaming)"
})

const MovingServicesListsEnum = Object.freeze({
    LIPAT_BAHAY: "Lipat Bahay",
    DELIVERIES: "Deliveries",
    OFFICE_RELOCATION: "Office Relocation",
    APARTMENT_TRANSFER: "Apartment Transfer"
})

const AcademicTutorialListsEnum = Object.freeze({
    ENGLISH_TUTORIAL: "English Tutorial",
    MATH_TUTORIAL: "Math Tutorial",
    SCIENCE_TUTORIAL: "Science Tutorial",
    CIVICS_TUTORIAL: "Civics Tutorial",
    FILIPINO_TUTORIAL: "Filipino Tutorial"
})

const InstrumentLessonListsEnum = Object.freeze({
    GUITAR_LESSON: "Guitar Lesson",
    PIANO_LESSON: "Piano Lesson",
    VIOLIN_LESSON: "Violin Lesson",
    DRUM_LESSON: "Drums Lesson",
    VOICE_LESSON: "Voice Lesson"
})

const CleaningServicesListsEnum = Object.freeze({
    DEEP_CLEANING: "Deep Cleaning",
    DRY_CLEANING: "Dry Cleaning",
    POST_CONSTRUCTION: "Post Construction",
    STEAM_STERLIZATION: "Superior Steam Sterilzation"
})

const PestControlServicesListsEnum = Object.freeze({
    TERMITE: "Termite Control",
    PEST: "General Pest Control",
    DISINFECTION: "Intensified Disinfection"
})

const TintAutoGlassServicesListsEnum = Object.freeze({
    TINT_INSTALL: "Car Tint Installation",
    GLASS_INSTALL: "Auto Glass Installation",
    CANOPE: "Canope (Acrylic Sheet or Rubber)",
    RUBBERS: "Automotive Rubbers (Moulding, Door, etc.)",
    HEADLIGHT_RESTORE: "Headlight Restoration",
})
export {
    GeneralContractorListsEnum,
    ElectricalContractorListsEnum,
    ApplianceRepairListsEnum,
    CarRentalListsEnum,
    CarRepairListsEnum,
    CarWashListsEnum,
    SewingListsEnum,
    DentalClinicListsEnum,
    AirconCleaningListsEnum,
    DecloggingListsEnum,
    UpholsteryListsEnum,
    MovingServicesListsEnum,
    AcademicTutorialListsEnum,
    InstrumentLessonListsEnum,
    CleaningServicesListsEnum,
    PestControlServicesListsEnum,
    TintAutoGlassServicesListsEnum
}