<template>
    <div class="card border-0 home-container" @scroll="onScroll">
        <!-- New UI starts here -->
        <div class="card-body home-inner">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <div class="border rounded shadow-sm p-2" style="color: #d3d3d3; cursor: pointer;" @click="router.push('/search')">
                        <mdicon name="magnify" /> <span >Search services</span>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>
            <!-- Popular Services -->
            <div class="row mt-3">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <div class="d-flex flex-row justify-content-between">
                        <span class="fw-bold fs-5">Popular Services</span>
                        <span class="fw-bold text-success" @click="router.push('/categories')" style="cursor: pointer;">See All</span>
                    </div>
                    <div class="popular-services-container mt-2" style="overflow: scroll;">
                        <div class="d-flex flex-row justify-content-start">
                            <div class="border rounded shadow-sm ms-2" style="display: inline-block; width: 150px; max-width: 150px;">
                                <div class="" style="width: 100%;">
                                    <img src="@/assets/construction.png" width="150px" height="80" alt="...">
                                </div>
                                <div class="p-2" style="font-size: 13px">
                                    Contractors
                                </div>
                            </div>
                            <div class="border rounded shadow-sm ms-2" style="display: inline-block; width: 150px; max-width: 150px;">
                                <div class="" style="width: 100%;">
                                    <img src="@/assets/house-services.jpeg" width="150px" height="80" alt="...">
                                </div>
                                <div class="p-2" style="font-size: 13px;">
                                    House Services
                                </div>
                            </div>
                            <div class="border rounded shadow-sm ms-2" style="display: inline-block; width: 150px; max-width: 150px;">
                                <div class="" style="width: 100%;">
                                    <img src="@/assets/car-maintenance.png" width="150px" height="80" alt="...">
                                </div>
                                <div class="p-2" style="font-size: 13px">
                                    Cars Maintenance
                                </div>
                            </div>
                            <div class="border rounded shadow-sm ms-2" style="display: inline-block; width: 150px; max-width: 150px;">
                                <div class="" style="width: 100%;">
                                    <img src="@/assets/teaching-services.png" width="150px" height="80" alt="...">
                                </div>
                                <div class="p-2" style="font-size: 13px">
                                    Teaching Services
                                </div>
                            </div>
                            <div class="border rounded shadow-sm ms-2" style="display: inline-block; width: 150px; max-width: 150px;">
                                <div class="" style="width: 100%;">
                                    <img src="@/assets/door.jpg" width="150px" height="80" alt="...">
                                </div>
                                <div class="p-2" style="font-size: 13px">
                                    Photography
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>

            <!-- What's new on Quickworks -->
            <div class="row mt-5">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <div class="d-flex flex-row justify-content-start">
                        <span class="fw-bold fs-5">What's new on Quickworks?</span>
                    </div>
                    <div class="popular-services-container mt-2" style="overflow: scroll;">
                        <div class="d-flex flex-row justify-content-start">
                            <div class="border rounded shadow-sm ms-2" style="display: inline-block; width: 250px; max-width: 250px;">
                                <div class="" style="width: 100%;">
                                    <img src="@/assets/coffee.jpg" width="220px" height="130" alt="...">
                                </div>
                            </div>
                            <div class="border rounded shadow-sm ms-2" style="display: inline-block; width: 250px; max-width: 250px;">
                                <div class="" style="width: 100%;">
                                    <img src="@/assets/lamp.jpg" width="220px" height="130" alt="...">
                                </div>
                            </div>
                            <div class="border rounded shadow-sm ms-2" style="display: inline-block; width: 250px; max-width: 250px;">
                                <div class="" style="width: 100%;">
                                    <img src="@/assets/coffee.jpg" width="220px" height="130" alt="...">
                                </div>
                            </div>
                            <div class="border rounded shadow-sm ms-2" style="display: inline-block; width: 250px; max-width: 250px;">
                                <div class="" style="width: 100%;">
                                    <img src="@/assets/lamp.jpg" width="220px" height="130" alt="...">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>

            <!-- Explore Quickworks -->
            <div class="row mt-5">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <div style="width: 100%;" class="p-1">
                        <img src="@/assets/explore.jpg" width="100%" style="border-radius: 10px;"/>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>

        </div>
        <!-- New UI ends here -->

        <!-- Loading Modal -->
        <Loading v-if="loading"/>
    </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import Modal from '../components/Modal.vue'
import search from '@/composables/search'
import Loading from '@/components/Loading.vue'
import store from '@/store'

export default {
    name: "Home",
    components: {
        Modal,
        Loading
    },
    setup() {
        const services = ref(null)
        const modalActive = ref(false)
        const serviceDetails = ref(null)
        const searchKey = ref('')
        const filteredData = ref({})
        const router = useRouter()
        const viewResults = ref(false)
        const loading = ref(false)

        const toggleModal = () => {
            modalActive.value = !modalActive
            serviceDetails.value = null
        }

        const searchWord = async(word) => {
            if (word === '') {
                return
            }
            searchKey.value = word
            viewResults.value = false
            loading.value = true
            const {response, error} = await search(word)
            loading.value = false
            if (error.value === null) {
                filteredData.value = response.value.results
                if (filteredData.value.length === 0) {
                    viewResults.value = true
                }
            }
        }

        const onScroll = ({target: {scrollTop, clientHeight, scrollHeight}}) => {
            if (scrollTop > 60) {
                store.methods.setSearchIcon(true)
            } else {
                store.methods.setSearchIcon(false)
            }
        }
        return {
            router,
            services,
            toggleModal,
            modalActive,
            serviceDetails,
            searchKey,
            filteredData,
            searchWord,
            viewResults,
            loading,
            onScroll
        }
    }
}
</script>

<style scoped>
.suggestions {
    padding: 3px;
    border-radius: 5px;
    cursor: pointer;
    transition: .2s all;
}

.suggestions:hover{
    transform: scale(1.2)
}

.service-info {
    font-size: 14px;
}
.contact-info {
    font-size: 14px
}
.service-label {
    font-size: 12px;
}

.service-value {
    font-size: 14px;
}
.addr {
    white-space: nowrap;
    width: calc(100% - 20px);
    overflow: hidden;
    text-overflow: ellipsis;
}.serv {
    white-space: nowrap;
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.service-container {
    display: grid;
    grid-template-columns: 35% 65%;
    justify-content: space-between;
}

.service-table:hover {
    background-color: #F1F0E8;
}

.home-container {
    height: 80vh;
    overflow: scroll;
}

.home-inner {
    height: 90vh;
    font-family: Arial, Helvetica, sans-serif;
}

.popular-services-container::-webkit-scrollbar {
    display: none;
}

.fade-enter-active,
.fade-leave-active {
    transition: all 0.6s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>