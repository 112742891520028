<template>
    <div class="card border-0">
        <div class="card-body">
            <div class="row">
                <div class="col-md-12 text-center">
                    <h4>Pending Service Requests</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8" >
                    <div class="d-flex flex-column align-items-start p-2 border rounded shadow-sm mt-1"  v-for="(item,index) in pending" v-if="Object.keys(pending).length > 0">
                        <!-- <div class="d-flex flex-row justify-content-center align-items-center" style="cursor: pointer;" @click="router.push('/serviceDetails/' + item.id)"> -->
                        <div class="d-flex flex-row justify-content-center align-items-center">
                            <img class="" src="@/assets/custom-search.png" width="25" style="border-radius: 50%;"/>
                            <div class="d-flex flex-column justify-content-center align-items-start ms-3 lh-2">
                                <div class="text-success fw-bold" style="font-size: 14px;">
                                    {{ item.store_name }} &nbsp;&nbsp;&nbsp;
                                </div>
                                <span class="addr" style="font-size: 12px;"> {{ item.address.address }} {{ item.address.barangay }} {{ item.address.city }}</span>
                            </div>
                        </div>
                        <div class="d-flex flex-row justify-content-start align-items-center mt-1 lh-1">
                            <span class="fw-bold" style="font-size: 12px;">Contact#: </span>&nbsp;
                            <div v-for="(contact,index) in item.contact_number">
                                <div class="contact-info fst-italic">{{  contact.contact_no }}
                                    <span v-if="index != Object.keys(item.contact_number).length-1">,</span>&nbsp;
                                </div>
                            </div>
                        </div>
                        <div class="service-info d-flex flex-row justify-content-start mt-1 lh-1">
                            <span class="fw-bold" style="font-size: 12px;">Services: </span>&nbsp;
                            <div class="serv">
                                <span class=" fst-italic" v-for="(services,index) in item.services">
                                    {{  services.description }}
                                    <span v-if="index != Object.keys(item.services).length-1">,</span>
                                </span>
                            </div>
                        </div>
                        <div class="service-info d-flex flex-row justify-content-start align-items-center mt-1">
                            <span class="fw-bold" style="font-size: 12px;">Actions: </span>&nbsp;
                            <span class="service-action-approve" @click="handleApprove(item.id)">Approve</span>&nbsp;&nbsp;
                            <span class="service-action-decline" @click="handleDecline(item.id)">Decline</span>
                        </div>
                    </div>
                    <div v-else class="text-center">
                        <img src="@/assets/blank_doc.png" width="170"/>
                        <p style="font-size: 12px;">No Pending Request Data</p>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>
        </div>
        <Loading v-if="loading"/>
    </div>
</template>

<script>
import { ref, onBeforeMount } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import getAllServices from '@/composables/services/getAllServices'
import approveServiceRequest from '@/composables/admin/approveServiceRequest'
import declineServiceRequest from '@/composables/admin/declineServiceRequest'
import Loading from '@/components/Loading.vue'

export default {
    name: "Pending",
    components: {
        Loading
    },
    setup () {
        const router = useRouter()
        const route = useRoute()
        const userToken = localStorage.getItem('token')
        const pending = ref({})
        const status = 0 // Pending
        const loading = ref(false)

        onBeforeMount (async() => {
            loading.value = true
            const {response, error} = await getAllServices(status)
            loading.value = false
            if (error.value === null) {
                pending.value = response.value
            }
        })

        const handleApprove = async(id) => {
            loading.value = true
            const {response, error} = await approveServiceRequest(id, userToken)
            loading.value = false
            if (error.value === null) {
                router.push('/')
            }
        }
        const handleDecline = async(id) => {
            loading.value = true
            const {response, error} = await declineServiceRequest(id, userToken)
            loading.value = false
            if (error.value === null) {
                router.push('/')
            }
        }

        return {
            router,
            pending,
            handleApprove,
            handleDecline,
            loading
        }
    }
}
</script>

<style scoped>

.service-info {
    font-size: 14px;
}
.contact-info {
    font-size: 14px
}
.service-label {
    font-size: 12px;
}

.service-value {
    font-size: 14px;
}

.service-status {
    font-size: 12px;
    background-color: #c2d8dc;
    padding: 3px;
    border-radius: 2px;
}
.service-action-approve {
    font-size: 12px;
    background-color: #c2d8dc;
    padding: 3px;
    border-radius: 2px;
    cursor: pointer;
}
.service-action-decline {
    font-size: 12px;
    background-color: #e4d0d0;
    padding: 3px;
    border-radius: 2px;
    cursor: pointer;
}

.addr {
    white-space: nowrap;
    width: calc(100% - 20px);
    overflow: hidden;
    text-overflow: ellipsis;
}.serv {
    white-space: nowrap;
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>