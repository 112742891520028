<template>

<div class="card border-0">
    <div class="card-body">
        <BackOnly />

        <div class="row mb-4">
            <div class="col-md-2"></div>
            <div class="col-md-8 d-flex flex-column align-items-center mt-3 text-center">
                <img src="@/assets/check.png" width="70"/>
                <span class="fw-bold fs-4">Lists of Registered Services</span>
                <span class="text-secondary" style="font-size: 12px">Admin can approve/decline depending on the submitted service request.</span>
            </div>
            <div class="col-md-2"></div>
        </div>

        <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8 text-center">
                <ul class="nav nav-underline mb-3 nav-justified" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active text-dark fw-bold" id="pills-pending-tab" data-bs-toggle="pill" data-bs-target="#pills-pending" type="button" role="tab" aria-controls="pills-pending" aria-selected="true">
                            <img src='@/assets/expired.png' width="30"/> <span class="">Pending</span>
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link text-dark fw-bold" id="pills-approved-tab" data-bs-toggle="pill" data-bs-target="#pills-approved" type="button" role="tab" aria-controls="pills-approved" aria-selected="false">
                            <img src='@/assets/thumbs-up.png' width="30" /> <span class="">Approved</span>
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link text-dark fw-bold" id="pills-declined-tab" data-bs-toggle="pill" data-bs-target="#pills-declined" type="button" role="tab" aria-controls="pills-declined" aria-selected="false">
                            <img src='@/assets/bad-review.png' width="30" /> <span class="">Declined</span>
                        </button>
                    </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">

                <!-- Pending Request -->
                <div class="tab-pane fade show active" id="pills-pending" role="tabpanel" aria-labelledby="pills-pending-tab">
                    <div class="row mt-2" v-for="(item, index) in pending" style="cursor: pointer;" v-if="Object.keys(pending).length > 0">
                        <div class="col-md-2"></div>
                        <div class="col-md-8 view-container">
                            <div class="border rounded-start shadow">
                                <img :src="baseUrl + item.logo" class="w-100 border rounded-start" height="150" v-if="item.logo"/>
                                <img src="@/assets/imgPlaceholder.png" class="w-100 border rounded-start" height="150" v-else/>
                            </div>
                            <div class="p-2 border rounded-end shadow" style="font-size: 13px; height: 150px; width: 100%; position: relative;">
                                <div class="d-flex flex-row justify-content-between">
                                    <div class="text-start">
                                        <span class="fw-bold text-success">{{ item.store_name }}</span><br>
                                        <span style="font-size: 13px;">{{ item.address.address }}</span><br>
                                        <span style="font-size: 12px; color: lightslategray">{{ item.services.sub_category }}</span>
                                    </div>
                                    <div>
                                        <mdicon name="check" size="18"/>
                                    </div>
                                </div>
                                <div class="btn-req d-flex justify-content-end mb-2 me-2">
                                    <span class="item-status bg-primary" @click="handleApprove(item.id)">Approve</span>
                                    <span class="item-status bg-warning ms-2" @click="handleDecline(item.id)">Decline</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2"></div>
                    </div>
                    <div v-else class="text-center">
                        <img src="@/assets/blank_doc.png" width="170"/>
                        <p style="font-size: 12px;">No Pending Request Data</p>
                    </div>
                </div>
                <!-- Pending Request End -->

                <!-- Approved Requests -->
                <div class="tab-pane fade" id="pills-approved" role="tabpanel" aria-labelledby="pills-approved-tab">
                    <div class="row mt-2" v-for="(item, index) in approved" style="cursor: pointer;" v-if="Object.keys(approved).length > 0">
                        <div class="col-md-2"></div>
                        <div class="col-md-8 view-container">
                            <div class="border rounded-start shadow">
                                <img :src="baseUrl + item.logo" class="w-100 border rounded-start" height="150" v-if="item.logo"/>
                                <img src="@/assets/imgPlaceholder.png" class="w-100 border rounded-start" height="150" v-else/>
                            </div>
                            <div class="p-2 border rounded-end shadow" style="font-size: 13px; height: 150px; width: 100%; position: relative;">
                                <div class="d-flex flex-row justify-content-between">
                                    <div class="text-start">
                                        <span class="fw-bold text-success">{{ item.store_name }}</span><br>
                                        <span style="font-size: 13px;">{{ item.address.address }}</span><br>
                                        <span style="font-size: 12px; color: lightslategray">{{ item.services.sub_category }}</span>
                                    </div>
                                    <div>
                                        <mdicon name="check" size="18"/>
                                    </div>
                                </div>
                                <div class="btn-req d-flex justify-content-end mb-2 me-2">
                                    <span class="item-status bg-info" @click="router.push('/serviceDetails/' + item.id)">View Details</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2"></div>
                    </div>
                    <div v-else class="text-center">
                        <img src="@/assets/blank_doc.png" width="170"/>
                        <p style="font-size: 12px;">No Approved Data</p>
                    </div>
                </div>
                <!-- Approved Request End -->

                <!-- Declined Request Start -->
                <div class="tab-pane fade" id="pills-declined" role="tabpanel" aria-labelledby="pills-declined-tab">
                    <div class="row mt-2" v-for="(item, index) in declined" style="cursor: pointer;" v-if="Object.keys(declined).length > 0">
                        <div class="col-md-2"></div>
                        <div class="col-md-8 view-container border shadow-sm rounded">
                            <div class="border rounded-start shadow">
                                <img :src="baseUrl + item.logo" class="w-100 border rounded-start" height="150" v-if="item.logo"/>
                                <img src="@/assets/imgPlaceholder.png" class="w-100 border rounded-start" height="150" v-else/>
                            </div>
                            <div class="p-2 border rounded-end shadow" style="font-size: 13px; height: 150px; width: 100%; position: relative;">
                                <div class="d-flex flex-row justify-content-between">
                                    <div class="text-start">
                                        <span class="fw-bold text-success">{{ item.store_name }}</span><br>
                                        <span style="font-size: 13px;">{{ item.address.address }}</span><br>
                                        <span style="font-size: 12px; color: lightslategray">{{ item.services.sub_category }}</span>
                                    </div>
                                    <div>
                                        <mdicon name="check" size="18"/>
                                    </div>
                                </div>
                                <div class="btn-req d-flex justify-content-end mb-2 me-2">
                                    <span class="item-status bg-info" @click="router.push('/serviceDetails/' + item.id)">View Details</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2"></div>
                    </div>
                    <div v-else class="text-center">
                        <img src="@/assets/blank_doc.png" width="170"/>
                        <p style="font-size: 12px;">No Declined Requests Data</p>
                    </div>
                </div>
                <!-- Declined Request End -->
                </div>
            </div>
            <div class="col-md-2"></div>
        </div>
    </div>
    <Loading v-if="loading"/>
</div>
</template>

<script>
import { ref, onBeforeMount } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import getAllServices from '@/composables/services/getAllServices'
import declineServiceRequest from '@/composables/admin/declineServiceRequest'
import approveServiceRequest from '@/composables/admin/approveServiceRequest'
import Loading from '@/components/Loading.vue'
import BackOnly from '@/components/Navigation/BackOnly.vue'

export default {
    name: "Requests",
    components: {
    Loading,
    BackOnly
},
    setup () {
        const userToken = localStorage.getItem('token')
        const pending = ref({})
        const approved = ref({})
        const declined = ref({})
        const router = useRouter()
        const loading = ref(false)
        const baseUrl = process.env.VUE_APP_BACKEND_API + '/logo/'

        onBeforeMount(async() => {
            loading.value = true
            const PendingData = await getAllServices(0) // Pending status
            if (PendingData.error.value === null) {
                pending.value = PendingData.response.value
            }

            const appovedData = await getAllServices(1) // Pending status
            if (appovedData.error.value === null) {
                approved.value = appovedData.response.value
            }
            const declinedData = await getAllServices(2) // Pending status
            loading.value = false
            if (declinedData.error.value === null) {
                declined.value = declinedData.response.value
            }
        })

        const handleApprove = async(id) => {
            loading.value = true
            const {response, error} = await approveServiceRequest(id, userToken)
            loading.value = false
            if (error.value === null) {
                router.push('/')
            }
        }
        const handleDecline = async(id) => {
            loading.value = true
            const {response, error} = await declineServiceRequest(id, userToken)
            loading.value = false
            if (error.value === null) {
                router.push('/')
            }
        }
        return {
            pending,
            approved,
            declined,
            handleApprove,
            handleDecline,
            router,
            loading,
            baseUrl
        }
    }
}
</script>

<style scoped>


.nav {
    font-family: Arial, Helvetica, sans-serif;
}

.view-container {
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: space-between;
}

.item-status {
    padding: 5px;
    border-radius: 5px;
}

.nav .nav-item .active {
    color: #F39C12 !important;
}

.btn-req {
    position: absolute;
    bottom: 0;
    right: 0;
}
</style>