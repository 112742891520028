const CategoryEnum = Object.freeze( {
    ELECTRONIC_REPAIR: "Electronic Repair",
    CONTRACTORS: "Contractors",
    CARS_MAINTENANCE: "Cars & Maintenance",
    TEACHING_SERVICES: "Teaching Services",
    HAIR_AND_BODY: "Hair & Body",
    BUSINESS: "Business",
    MUSICAL_ARTS: "Musical Arts",
    PHOTOGRAPHY: "Photography",
    HOUSE_SERVICES: "House Services",
    MEDICAL_SERVICES: "Medical Services"
})

export default CategoryEnum